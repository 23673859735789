<template>
  <div class="p-2">
    <v-data-table
      dense
      :headers="headers"
      :items="listUsers"
      sort-by="name"
      :search="search"
      height="auto"
      fixed-header
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
    >
      <template v-slot:top>
        <div class="row justify-end">
          <div
            class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"
          >
            <v-text-field
              v-model="search"
              :label="$t('table.search')"
              class="pl-1"
            ></v-text-field>
          </div>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top v-if="isUpdate">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="ml-3"
              @click="edit(item, false)"
              v-bind="attrs"
              v-on="on"
            >
              mdi mdi-square-edit-outline
            </v-icon>
          </template>
          <span>{{ $t("table.edit") }}</span>
        </v-tooltip>
        <v-tooltip top v-if="isCopy">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="ml-3"
              @click="edit(item, true)"
              v-bind="attrs"
              v-on="on"
            >
              feather icon-copy
            </v-icon>
          </template>
          <span>Clonar</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <ButtonPrintComponent />
  </div>
</template>

<script>
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";

export default {
  props: {
    listUsers: {
      required: true,
    },
    headers: {
      required: true,
    },
    isUpdate: {
      required: true,
    },
    isCopy: {
      required: true,
    },
    edit: Function,
  },
  data() {
    return {
      search: "",
    };
  },
  components: {
    ButtonPrintComponent,
  },
};
</script>
