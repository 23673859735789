<template>
  <div id="containerbar">
    <div class="rightbar">
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("rol.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("rol.home") }}</a>
                </li>

                <li class="breadcrumb-item">{{ $t("rol.administration") }}</li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="
                showAction(1) &&
                  withPermissionName(permissionsConst.ADM_ROL_ADD_01)
              "
              @click="changeAction(2)"
            >
              <i class="feather icon-plus mr-1"></i> {{ $t("global.create_role") }}
            </button>
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3) || showAction(4)"
              @click="changeAction(1)"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>

      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title mb-0">
                      <i class="feather icon-lock mr-1"></i>
                      {{ $t(titleAction) }}
                    </h5>
                  </div>
                </div>
              </div>
              <TableRolUserComponent
                v-if="showAction(1)"
                :listUsers="listUsers"
                :headers="headers"
                :edit="edit"
                :isCopy="withPermissionName(permissionsConst.ADM_ROL_COPY_03)"
                :isUpdate="withPermissionName(permissionsConst.ADM_ROL_EDIT_02)"
              />
              <div
                class="card-body"
                v-if="showAction(2) || showAction(3) || showAction(4)"
              >
                <div v-bind:class="[action != 1 ? 'card vue-tree' : 'card']">
                  <div class="row">
                    <div class="col-md-6 col-lg-6 col-xs-6 col-sm-6">
                      <div class="row">
                        <div class="col-md-12">
                          <h5>Información rol</h5>
                        </div>
                      </div>
                      <validation-observer ref="observer" v-slot="{ invalid }">
                        <form @submit.prevent="sendPermission">
                          <div class="row">
                            <div class="col-md-12">
                              <validation-provider
                                v-slot="{ errors }"
                                name="name"
                                rules="required|max:30"
                              >
                                <v-text-field
                                  v-model="model.name"
                                  :counter="30"
                                  :error-messages="errors"
                                  :label="$t('label.name')"
                                  required
                                  autocomplete="off"
                                ></v-text-field>
                              </validation-provider>
                            </div>
                            <div class="col-md-12">
                              <validation-provider
                                v-slot="{ errors }"
                                name="comment"
                                rules="required|min:1|max:500|"
                              >
                                <v-textarea
                                  outlined
                                  v-model="model.description"
                                  :error-messages="errors"
                                  :label="$t('label.description')"
                                  counter
                                  maxlength="500"
                                  rows="4"
                                  required
                                  autocomplete="off"
                                />
                              </validation-provider>
                            </div>
                            <div class="col-md-12 text-right">
                              <button
                                class="btn btn-outline-success"
                                v-if="!showAction(5)"
                                type="submit"
                                :disabled="
                                  invalid || sending || master.length < 1
                                "
                              >
                                <i class="feather icon-save mr-1"></i>
                                {{
                                  model.id
                                    ? "Modifcar Rol"
                                    : $t("global.save_rol")
                                }}
                              </button>
                            </div>
                          </div>
                        </form>
                      </validation-observer>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xs-6 col-sm-6">
                      <div class="row">
                        <div class="col-md-12">
                          <h5>{{ $t("rol.permissions") }}</h5>
                        </div>
                      </div>
                      <treeselect
                        class="scroll-tree"
                        :always-open="true"
                        :multiple="true"
                        :options="options"
                        value-consists-of="ALL_WITH_INDETERMINATE"
                        sort-value-by="ORDER_SELECTED"
                        v-model="value"
                        :clearable="false"
                        :limit="0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module RolView
 * @desc Roles
 * @author Jesus Teran
 */
import TableRolUserComponent from "@/components/roles/TableRolUserComponent";
import { UtilFront, PermissionConstants } from "@/core";
import { RolRequest, ExcelRequest } from "@/core/request";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { PERMISIONS_ROLE, ROLES } from "@/core/DataTableHeadersLocale";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import permissions from "@/assets/json/permissions.json";
import { mapState } from "vuex";

export default {
  data() {
    return {
      title: "rol.roles",
      listUsers: [],
      headers: ROLES(),
      headersPermmision: PERMISIONS_ROLE(),
      permissionsList: [],
      value: [],
      master: [],
      options: permissions,
      search: "",
      action: 1,
      sending: false,
      branchOffice: 0,
      model: {
        name: "",
        description: "",
        id: "",
      },
      loading: false,
      permissionsConst: PermissionConstants,
    };
  },
  computed: {
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    titleAction() {
      if (this.action == 1) {
        return "rol.roles";
      } else if (this.action == 2) {
        return "rol.register_role";
      } else if (this.action == 3) {
        return "rol.edit_role";
      } else if (this.action == 4) {
        return "Copiar rol";
      }
      return "Listado";
    },
  },
  methods: {
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      if (action == 1) {
        this.$refs.observer.reset();
        this.model = {};
        this.value = [];
        this.action = action;
      } else {
        this.action = action;
      }
    },
    /**
     * Realiza el registro de los roles nuevos
     * @method
     */
    addPermission() {
      this.loading = UtilFront.getLoadding();
      let addRol = {
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOffice,
        },
        name: this.model.name,
        description: this.model.description,
        permissionsJson: this.master,
      };
      RolRequest.addPermission(addRol)
        .then((response) => {
          let { success, message } = response.data.data;
          if (success) {
            this.alert("success", "Add_Success_0001");
            this.changeAction(1);
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
          this.listAll();
        });
    },
    /**
     * Realiza la modificación de los roles
     * @method
     */
    updatePermission() {
      this.loading = UtilFront.getLoadding();
      let updateRol = {
        userBranchOffice: {
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.branchOffice,
        },
        idRole: this.model.id,
        name: this.model.name,
        description: this.model.description,
        permissionsJson: this.master,
      };
      RolRequest.updatePermission(updateRol)
        .then((response) => {
          let { success, message } = response.data.data;
          if (success) {
            this.alert("success", "Update_Success_0001");
            this.changeAction(1);
          } else {
            this.alert("error", message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
          this.listAll();
        });
    },
    sendPermission() {
      if (this.master.length <= 0) {
        this.alert("error", "Message_Rol_0001");
      } else {
        this.model.id ? this.updatePermission() : this.addPermission();
      }
    },
    /**
     * Realiza la consulta para realizar el listado de los roles registrados
     * @method
     */
    async listAll() {
      this.loading = UtilFront.getLoadding();
      await RolRequest.listAllWithPermmisions({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.branchOffice,
      })
        .then((res) => {
          let { data } = res.data.data;
          if (data) {
            this.listUsers = data.map((obj) => {
              obj.totalPermmisions = JSON.parse(obj.rolJson).length;
              return obj;
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Obtine la información del rol para realizar la modificación y/o la funcionalidad de clonar el rol
     * @method
     * @param {Object} object la información del rol a modificar o clonar
     * @param {Boolean} copy define si la funcionalidad es modificar o clonar el rol
     */
    edit(object, copy) {
      if (copy) {
        this.value = JSON.parse(object.rolJson);
        this.model.name = "";
        this.model.id = null;
        this.model.description = "";
        this.changeAction(4);
      } else {
        this.value = JSON.parse(object.rolJson);
        this.model.name = object.name;
        this.model.id = object.idRole;
        this.model.description = object.description;
        this.changeAction(3);
      }
    },
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    excel() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportRole({});
    },
  },
  async created() {
    this.branchOffice = this.branchOfficeState.idBranchoffice;
    await this.listAll();
  },
  watch: {
    value() {
      this.master = this.value.filter((v, i) => this.value.indexOf(v) === i);
    },
    "$i18n.locale": function() {
      this.headers = ROLES();
      this.headersPermmision = PERMISIONS_ROLE();
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    AlertMessageComponent,
    TableRolUserComponent,
    Treeselect,
  },
};
</script>

<style>
.vue-treeselect--open-below .vue-treeselect__menu {
  max-height: 550px !important;
  border-radius: 10px;
  border: 1px solid #ddd;
  color: rgba(0, 0, 0, 0.87);
}
.vue-treeselect__control {
  display: none;
}
.vue-tree {
  height: 600px;
}
</style>
