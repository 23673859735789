var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.listUsers,"sort-by":"name","search":_vm.search,"height":"auto","fixed-header":"","footer-props":{
      'items-per-page-text': _vm.$t('table.item_table'),
      'items-per-page-all-text': _vm.$t('table.all'),
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":_vm.$t('table.loading'),"no-data-text":_vm.$t('table.no_results_available'),"no-results-text":_vm.$t('table.no_results_search')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row justify-end"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 pr-6 text-right"},[_c('v-text-field',{staticClass:"pl-1",attrs:{"label":_vm.$t('table.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.isUpdate)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item, false)}}},'v-icon',attrs,false),on),[_vm._v(" mdi mdi-square-edit-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("table.edit")))])]):_vm._e(),(_vm.isCopy)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"small":""},on:{"click":function($event){return _vm.edit(item, true)}}},'v-icon',attrs,false),on),[_vm._v(" feather icon-copy ")])]}}],null,true)},[_c('span',[_vm._v("Clonar")])]):_vm._e()]}}],null,true)}),_c('ButtonPrintComponent')],1)}
var staticRenderFns = []

export { render, staticRenderFns }